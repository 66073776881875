@font-face {
  font-family: "apex";   /*Can be any text*/
  src: local("apex-legends-font"),
    url("./components/fonts/apex-legends-font.otf") format("opentype");
}

.container{
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.legends-index{
  display: flex;
  flex-direction: row;
}

body{
  font-family: 'apex';
}

div.title, .nav-item, h1.title{
  font-size: large;
  font-weight: 700;
  text-align: center;
  letter-spacing: 2px;
}

.nav-item{
  padding: 0;
  margin: 0;
}

.nav-item.active{
  text-decoration: underline;
}

a, a:visited{
  text-decoration: none;
  color: white;
}

.nav-item:hover, .spin-btn:hover, .rec-tile:hover{
  cursor: pointer;
}

.nav-bar {
  display: flex;
  color: white;
  padding: 1rem;
  justify-content: space-between;
  text-align: start;
}

.nav-con-r{
  display:flex;
  justify-content: space-between;
}

.roulette-row{
  display: flex;
  margin: auto;
  justify-content: center;
}

.square{
  display:flex;
  flex-direction: column;
  margin: 0 2rem;
  padding: 25px;
  max-width: 336px;
  min-width: 10px;
}

.centered{
  display: block;
  margin: auto;
}

.bg-red{
  background-color: tomato;
}

.spin-btn{
  border: 1px rgb(43, 41, 41) solid;
  border-radius: 5px;
  padding: 1rem 2rem;
  color: white;
  font-family: "apex";
  letter-spacing: 2px;
}

.hover-me img{
  cursor: pointer;
}

.row{
  display: flex;
  justify-content: space-evenly;
}

.rec-tile{
  border: solid 2px black;
  padding: 5% 10%;
  width: 10rem;
  height: 4rem;
}

div *.title{
  color: black;
}

.hidden{
  display: none;
}

.legend-pic{
  max-width: 65%;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: inherit;
}

.legend-pic:hover{
  background-color: rgb(223, 223, 223);
}

.bio-container{
  display:flex;
  justify-content: center;
}

.legend-bio{
  padding: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border: solid black 1px;
  background-color: #3e3e3e;
  min-width: 640px;
  min-height: 360px;
  position:fixed;
  color: rgb(255, 255, 255);
  letter-spacing: 2px;
}

.legend-bio-header{
  height: 12px;
  width: 100%;
  background-color: tomato;
}

.spread{
  margin: 0 2rem;
}

.hide-me{
  display: none;
}

/*place this at the bottom to override ind comp. vert margins*/
.v-spacer{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.h-spacer{
  margin-left: 1rem;
  margin-right: 1rem;
}
/*Need to add responsiveness to the roullete squares*/

@media(max-width: 1445px){
  div.title, .nav-item, h1.title{
    font-size: medium;
    font-weight: 700;
    text-align: center;
    letter-spacing: 2px;
  }
}
/*Need to add responsiveness to the rec tiles*/
